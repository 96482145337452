var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "policies-in-table",
      on: {
        click: function ($event) {
          $event.stopPropagation()
        },
      },
    },
    [
      _c(
        "button",
        {
          attrs: { type: "button" },
          on: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.toggleShow.apply(null, arguments)
            },
          },
        },
        [_vm._v("\n    " + _vm._s(_vm.buttonName) + "\n  ")]
      ),
      _c(
        "ul",
        { staticClass: "user-policies-list", class: { open: _vm.show } },
        _vm._l(_vm.userPolicies, function (userPolicy, userPolicyIndex) {
          return _c("li", { key: userPolicyIndex }, [
            _c("span", [
              userPolicy.policy
                ? _c("span", [_vm._v(_vm._s(userPolicy.policy.name))])
                : _vm._e(),
              _vm._v(" - " + _vm._s(_vm.branchName(userPolicy.branch_id))),
            ]),
          ])
        }),
        0
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }